import { mapActions, mapGetters } from "vuex"
import PitInput from "@/components/shared/input"
import PitCascadedInput from "@/components/shared/cascaded-input"
import { ARRAY, FORM_INSTANCE_FIELD_TYPES, WIDGET, MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  name: "IntakeForm",
  data() {
    return {
      formInstanceFieldValues               : {},
      intakeFormHasValidationError          : false,
      isError                               : false,
      formInstanceFieldIdToConditionsMapping: {},
      formInstanceFieldsToShowMap           : {}
    }
  },
  components: {
    PitInput,
    PitCascadedInput
  },
  computed: {
    ...mapGetters({
      channels              : "channels/channels",
      formInstances         : "formInstances/formInstances",
      isFormInstanceUpdated : "formInstances/isFormInstanceUpdated",
      isUpdatingFormInstance: "formInstances/isUpdatingFormInstance",
      formTemplates         : "formTemplates/formTemplates",
      report                : "auth/report"
    }),

    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },

    formInstancesWithSortedFields() {
      const formInstances = this.formInstances

      for (const formInstance of formInstances) {
        formInstance.formInstanceFields.sort(
          (first, second) => first.sortingOrder - second.sortingOrder
        )
      }

      return formInstances
    }
  },
  methods: {
    ...mapActions({
      updateFormInstance: "formInstances/updateFormInstance"
    }),
    isOptionListCascaded(formInstanceField) {
      return !!formInstanceField.optionList.optionListItems.find(optionListItem => optionListItem.parentId)
    },
    handleCascadeInputBlur(onBlur, validate) {
      onBlur()
      validate()
    },
    async validateFormInstanceValues() {
      const intakeFormValidationObserver = this.$refs["intake_form_validation_observer"]
      this.intakeFormHasValidationError  = !await intakeFormValidationObserver.validate()
      if (this.intakeFormHasValidationError) {
        const firstErrorFormInstanceField = Object.keys(intakeFormValidationObserver.errors).find(fieldName =>
          intakeFormValidationObserver.errors[fieldName].length
        )
        const formInstanceFieldId         = firstErrorFormInstanceField.split("_")[ARRAY.SECOND]
        if (this.$refs[`select_form_instance_field_${formInstanceFieldId}`]) {
          this.$refs[`select_form_instance_field_${formInstanceFieldId}`][ARRAY.FIRST].focus()
        }
        return false
      }
      return true
    },
    onNodeSelect(selectedNodes, formInstanceId, formInstanceFieldId, validate) {
      if (selectedNodes.length) {
        this.formInstanceFieldValues[formInstanceId][formInstanceFieldId] =
          selectedNodes[ARRAY.FIRST]
      } else {
        this.formInstanceFieldValues[formInstanceId][formInstanceFieldId] = null
      }
      validate(this.formInstanceFieldValues[formInstanceId][formInstanceFieldId])
      this.checkConditions(formInstanceFieldId, selectedNodes[ARRAY.FIRST], formInstanceId)
    },
    handleValidate(value, validate) {
      validate(value)
    },
    async updateFormInstanceValues() {
      if (!await this.validateFormInstanceValues()) {
        return
      }
      const formInstanceFields          = this.formInstancesWithSortedFields[0].formInstanceFields
      const formInstanceFieldShowValues = {}
      for (const formInstanceField of formInstanceFields) {
        formInstanceFieldShowValues[formInstanceField.id] = formInstanceField.show
      }
      for (const formInstanceId of Object.keys(this.formInstanceFieldValues)) {
        const data               = []
        const formInstanceFields = this.formInstanceFieldValues[formInstanceId]
        for (const formInstanceFieldId of Object.keys(formInstanceFields)) {
          const formInstanceFieldValue = formInstanceFields[formInstanceFieldId]
          if (!Array.isArray(formInstanceFieldValue)) {
            data.push({
              formInstanceFieldId: +formInstanceFieldId,
              value              : formInstanceFieldValue,
              show               : this.formInstanceFieldsToShowMap[formInstanceFieldId]
            })
          }
        }
        this.updateFormInstance({
          id  : formInstanceId,
          data: data
        })
      }
    },
    getItemsForSelection(formInstanceField) {
      if (formInstanceField.type === FORM_INSTANCE_FIELD_TYPES.OPTION_LIST) {
        const isOptionListCascaded = this.isOptionListCascaded(formInstanceField)
        const optionListItems      = formInstanceField.optionList.optionListItems.slice()
          .sort((first, second) => first.sortingOrder - second.sortingOrder)
          .map(optionListItem => ({
            id          : optionListItem.id,
            name        : optionListItem.name,
            parentId    : optionListItem.parentId,
            displayValue: optionListItem.optionListItemTranslations.length ?
              optionListItem.optionListItemTranslations[ARRAY.FIRST].translation
              ?? optionListItem.name
              : optionListItem.name
          }))

        if (isOptionListCascaded) {
          return this.generateNestedOptionListItems(optionListItems)
        } else {
          return optionListItems
        }
      }
    },
    generateNestedOptionListItems(optionListItems) {
      const optionlistItemsMap      = {}
      const cascadedOptionListItems = []

      optionListItems.forEach(optionListItem => {
        optionlistItemsMap[optionListItem.id] = { ...optionListItem, children: [] }
      })

      optionListItems.forEach(optionListItem => {
        const parent = optionlistItemsMap[optionListItem.parentId]
        if (parent) {
          parent.disabled = true
          parent.children.push(optionlistItemsMap[optionListItem.id])
        } else {
          cascadedOptionListItems.push(optionlistItemsMap[optionListItem.id])
        }
      })

      return cascadedOptionListItems
    },
    getFieldLabel(formInstanceField) {
      let fieldLabel
      for (const fieldTranslation of formInstanceField.field.fieldTranslations) {
        if (fieldTranslation.languageId === this.report.languageId) {
          fieldLabel = fieldTranslation.labelTranslation
        }
      }

      fieldLabel = fieldLabel || formInstanceField.label

      return formInstanceField.mandatory ? `${fieldLabel}*` : fieldLabel
    },

    getRules(formInstanceField) {
      const rules = []

      if (formInstanceField.mandatory) {
        rules.push("required")
      }
      if (formInstanceField.widget === WIDGET.TEXT_FIELD) {
        rules.push(`max:${MAX_CHARACTER_LIMIT.SHORT_TEXT}`)
      }

      return rules.join("|")
    },
    checkConditions(formInstanceFieldId, fieldValue, formInstanceId) {
      const formInstanceFieldConditions = this.formInstanceFieldIdToConditionsMapping[formInstanceFieldId]
      if (formInstanceFieldConditions?.length) {
        const formInstanceFieldsToDisplayConditionally = []
        const childFormInstanceFieldIds                = []
        for (const condition of formInstanceFieldConditions) {
          if (condition.value === fieldValue) {
            formInstanceFieldsToDisplayConditionally.push(condition.childFormInstanceFieldId)
          }
          childFormInstanceFieldIds.push(condition.childFormInstanceFieldId)
        }

        for (const childFormInstanceFieldId of childFormInstanceFieldIds) {
          const copyOfFormInstanceFieldsToShowMap = { ...this.formInstanceFieldsToShowMap }
          if (formInstanceFieldsToDisplayConditionally.includes(childFormInstanceFieldId)) {
            copyOfFormInstanceFieldsToShowMap[childFormInstanceFieldId] = true
          } else {
            copyOfFormInstanceFieldsToShowMap[childFormInstanceFieldId]            = false
            this.formInstanceFieldValues[formInstanceId][childFormInstanceFieldId] = null
          }
          this.formInstanceFieldsToShowMap = { ...copyOfFormInstanceFieldsToShowMap }
        }
      }
    }
  },
  watch: {
    formInstances: {
      immediate: true,
      handler  : function(newValue) {
        const formInstanceFieldValues = {}
        for (const formInstance of newValue) {
          formInstanceFieldValues[formInstance.id] = {}
          for (const formInstanceField of formInstance.formInstanceFields) {
            formInstanceFieldValues[formInstance.id][formInstanceField.id] =
                formInstanceField.formInstanceFieldValues[0].value
          }
          const formTemplateConfigurationIdToFormInstanceFieldIdMap = {}
          const formTemplateConfigurations                          = formInstance
            .formTemplate.formTemplateConfigurations
          for (const formTemplateConfiguration of formTemplateConfigurations) {
            const formInstanceFieldId                                                         = formInstance
              .formInstanceFields.find(formInstanceField =>
                formInstanceField.fieldId === formTemplateConfiguration.fieldId).id
            formTemplateConfigurationIdToFormInstanceFieldIdMap[formTemplateConfiguration.id] = formInstanceFieldId
            this.formInstanceFieldsToShowMap[formInstanceFieldId]                             = true
          }
          for (const formTemplateConfiguration of formTemplateConfigurations) {
            const formInstanceFieldId = formTemplateConfigurationIdToFormInstanceFieldIdMap[
              formTemplateConfiguration.id]
            const fieldConditions     = formTemplateConfiguration.fieldConditions
            if (fieldConditions.length) {
              for (const fieldCondition of fieldConditions) {
                for (const fieldConditionCriterion of fieldCondition.fieldConditionCriteria) {
                  const childFormInstanceFieldId = formTemplateConfigurationIdToFormInstanceFieldIdMap[
                    fieldConditionCriterion.formTemplateConfigurationId
                  ]
                  const conditionCriterion       = {
                    value: fieldConditionCriterion.value,
                    childFormInstanceFieldId
                  }
                  if (formInstanceFieldValues[formInstance.id][childFormInstanceFieldId]) {
                    this.formInstanceFieldsToShowMap[childFormInstanceFieldId] = true
                  } else {
                    this.formInstanceFieldsToShowMap[childFormInstanceFieldId] = false
                  }

                  if (this.formInstanceFieldIdToConditionsMapping[formInstanceFieldId]) {
                    this.formInstanceFieldIdToConditionsMapping[formInstanceFieldId].push(conditionCriterion)
                  } else {
                    this.formInstanceFieldIdToConditionsMapping[formInstanceFieldId] = [conditionCriterion]
                  }
                }
              }
            }
          }
        }
        this.formInstanceFieldValues = formInstanceFieldValues
      }
    },
    isFormInstanceUpdated: function(newValue) {
      if (newValue) {
        this.$router.push({
          name  : "messages",
          params: {
            locale : this.$route.params.locale,
            channel: this.$route.params.channel
          }
        })
      }
    }
  }
}